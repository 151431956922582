import React from 'react';

const Github = () => {
	return (
		<div>
			<h1>
				Sample Work on GitHub <i className='fab fa-github' />
			</h1>
		</div>
	);
};

export default Github;
