import React from 'react'
import ShortfinLogo from './shortfinLogo.png';

const Footer = () => {
    return (
        <div className='fixed-bottom' style={{ padding: '1rem', backgroundColor: '#191919'}}>
            &copy; 2020 Shortfin Software, LLC. &nbsp; &nbsp;
            <img src={ShortfinLogo} alt="shortfin logo" style={{ width: '2rem' }}/> 
        </div>
    )
}

export default Footer
