import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Header from './components/layout/Header/Header';
import Footer from './components/layout/Footer/Footer';
import Home from './components/pages/Home';
import Github from './components/pages/Github';
import Contact from './components/pages/Contact';
import './App.css';

function App() {
	return (
        <Router>
            <div className='App' style={{ textAlign: 'center', color: 'white' }}>
                <Header />
                <div>
                    <Switch>
                        <Route exact path='/' component={Home} />
                        <Route exact path='/github' component={Github} />
                        <Route exact path='/contact' component={Contact} />
                    </Switch>
                </div>
                <Footer />
            </div>
        </Router>
	);
}

export default App;
