import React from 'react';
import dubbsImage1 from '../../images/dubbsProg1.jpg';

const Home = () => {
	return (
		<div className='home'>
			<div
				className='jumbotron'
				style={{
					textAlign: 'center',
					color: 'black',
					width: '90%',
					maxWidth: '700px',
					margin: '4rem auto'
				}}>
				<img
					src={dubbsImage1}
					alt='logo1'
					style={{
						width: '400px',
						maxWidth: '100%',
						borderRadius: '10%'
					}}
				/>
				<hr className='my-4' />
				<h5>
					Software Engineer | Fullstack Web Developer | Data Analyst |
					Shark Conservation Enthusiast
				</h5>
			</div>
		</div>
	);
};

export default Home;
