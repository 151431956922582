import React from 'react'
import NavBar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import DubbsLogo1 from './dubbsProg1.png';
import './Header.css';

const Header = () => {
    return (
        <NavBar className="navbar" expand="lg" style={{ backgroundColor: '#191919' }}>
            <Nav.Link href="/"><NavBar.Brand className="text-light ml-auto"><img src={DubbsLogo1} alt="" style={{width: '5rem' }}/></NavBar.Brand></Nav.Link>
            <NavBar.Toggle />
            <NavBar.Collapse id="navbar-nav">
                <Nav className="ml-auto">
                    <Nav.Link className="text-light mr-3" href="/"><i className="fa fa-home" /> Home</Nav.Link>
                    <Nav.Link className="text-light mr-3" href="/github"><i className="fab fa-github" /> GitHub</Nav.Link>
                    <Nav.Link className="text-light mr-3" href="/contact"><i className="fas fa-envelope-open-text" /> Contact</Nav.Link>
                </Nav>
            </NavBar.Collapse>
        </NavBar>
    )
}

export default Header
